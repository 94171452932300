/* Options:
Date: 2024-09-11 13:33:37
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5020

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface ISiteReferenceRequest extends ISiteReferenceEntity
{
}

export interface ISiteReferenceEntity
{
    siteId: number;
}

/**
* Sign Up
*/
// @Api(Description="Sign Up")
// @DataContract
export class Register implements IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin?: boolean;

    // @DataMember(Order=10)
    public errorView: string;

    // @DataMember(Order=11)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Register>) { (Object as any).assign(this, init); }
}

export interface IEntityLocalizeRequest
{
    languageCode: string;
}

export class OperationRequest
{
    public name: string;
    public body: Object;

    public constructor(init?: Partial<OperationRequest>) { (Object as any).assign(this, init); }
}

export class SiteEvent<T>
{
    public id: number;
    public name: string;
    public body: T;
    public siteId: number;

    public constructor(init?: Partial<SiteEvent<T>>) { (Object as any).assign(this, init); }
}

export class GlobalEvent<T>
{
    public id: number;
    public name: string;
    public body: T;

    public constructor(init?: Partial<GlobalEvent<T>>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class BaseResponse implements IBaseResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export interface IBaseResponse
{
}

export enum AuthRole
{
    Anonymous = 1,
    SiteOwner = 2,
    SiteUser = 3,
    VisualContentCreator = 4,
    VisualContentManager = 5,
    Admin = 6,
    AdminReadOnly = 7,
    SiteClient = 8,
    SiteAnonymousClient = 9,
}

export class GetAccountSettingsResponse extends BaseResponse
{
    public id?: number;
    public userAuthId?: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public primaryEmail: string;
    public legalName: string;
    public country: string;
    public state: string;
    public city: string;
    public address: string;
    public phoneNumber: string;
    public businessNumber: string;
    public postalCode: string;
    public selectedSiteId?: number;
    public stripeAccountId: string;
    public payPalClientId: string;
    public isDeleted?: boolean;

    public constructor(init?: Partial<GetAccountSettingsResponse>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public roles: string[];

    // @DataMember(Order=8)
    public permissions: string[];

    // @DataMember(Order=9)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=10)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

export class SessionInfoResponse extends BaseResponse
{
    public id: string;
    public userAuthId: number;
    public userName: string;
    public displayName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phoneNumber: string;
    public address: string;
    public country: string;
    public mainRole: AuthRole;

    public constructor(init?: Partial<SessionInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SendPasswordResetEmailResponse extends BaseResponse
{
    public success: boolean;
    public message: string;

    public constructor(init?: Partial<SendPasswordResetEmailResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ResetPasswordResponse extends BaseResponse
{
    public success: boolean;
    public message: string;

    public constructor(init?: Partial<ResetPasswordResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PingResponse extends BaseResponse
{
    public message: string;

    public constructor(init?: Partial<PingResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PingSleepResponse
{
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<PingSleepResponse>) { (Object as any).assign(this, init); }
}

export class GetAccountSettings implements IReturn<GetAccountSettingsResponse>
{
    public getApiRoute() { return '/api/auth'; }

    public constructor(init?: Partial<GetAccountSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAccountSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAccountSettingsResponse(); }
}

export class UpdateAccountSettings implements IReturnVoid
{
    public getApiRoute() { return '/api/auth'; }
    public firstName: string;
    public lastName: string;
    public primaryEmail: string;
    public legalName: string;
    public country: string;
    public state: string;
    public city: string;
    public address: string;
    public phoneNumber: string;
    public businessNumber: string;
    public postalCode: string;

    public constructor(init?: Partial<UpdateAccountSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAccountSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AuthenticateClient implements IReturn<AuthenticateResponse>, ISiteReferenceRequest
{
    public getApiRoute() { return '/api/auth'; }
    public provider: string;
    public userName: string;
    public password: string;
    public rememberMe?: boolean;
    public siteId: number;

    public constructor(init?: Partial<AuthenticateClient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuthenticateClient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

/**
* Sign Up
*/
// @Api(Description="Sign Up")
export class RegisterClient extends Register implements IReturn<RegisterResponse>, ISiteReferenceRequest
{
    public getApiRoute() { return '/api/auth'; }
    public siteId: number;

    public constructor(init?: Partial<RegisterClient>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'RegisterClient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegisterResponse(); }
}

export class SessionInfo implements IReturn<SessionInfoResponse>
{
    public getApiRoute() { return '/api/auth'; }

    public constructor(init?: Partial<SessionInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SessionInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfoResponse(); }
}

export class GenerateTempToken implements IReturn<string>
{
    public getApiRoute() { return '/api/auth'; }

    public constructor(init?: Partial<GenerateTempToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateTempToken'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/update-account-password")
export class UpdateKmbAccountPassword implements IReturnVoid
{
    public getApiRoute() { return '/api/auth'; }
    public oldPassword: string;
    public newPassword: string;
    public newPasswordConfirm: string;

    public constructor(init?: Partial<UpdateKmbAccountPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKmbAccountPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AuthenticateUser implements IReturn<AuthenticateResponse>
{
    public getApiRoute() { return '/api/auth'; }
    public provider: string;
    public userName: string;
    public password: string;
    public rememberMe?: boolean;
    public isAuto?: boolean;
    public token: string;

    public constructor(init?: Partial<AuthenticateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuthenticateUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

/**
* Sign Up
*/
// @Api(Description="Sign Up")
export class RegisterUser extends Register implements IReturn<RegisterResponse>
{
    public getApiRoute() { return '/api/auth'; }

    public constructor(init?: Partial<RegisterUser>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'RegisterUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegisterResponse(); }
}

export class SendPasswordResetEmail implements IReturn<SendPasswordResetEmailResponse>
{
    public getApiRoute() { return '/api/auth'; }
    public email: string;

    public constructor(init?: Partial<SendPasswordResetEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPasswordResetEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPasswordResetEmailResponse(); }
}

export class ResetPassword implements IReturn<ResetPasswordResponse>
{
    public getApiRoute() { return '/api/auth'; }
    public secretCode: string;
    public newPassword: string;

    public constructor(init?: Partial<ResetPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResetPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResetPasswordResponse(); }
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendUserSignupEmail implements IReturnVoid, IEntityLocalizeRequest
{
    public getApiRoute() { return '/api/auth'; }
    public userAuthId: number;
    public confirmPassword: string;
    public languageCode: string;

    public constructor(init?: Partial<SendUserSignupEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUserSignupEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendUserSignupSocialEmail implements IReturnVoid, IEntityLocalizeRequest
{
    public getApiRoute() { return '/api/auth'; }
    public userAuthId: number;
    public socialNetworkName: string;
    public languageCode: string;

    public constructor(init?: Partial<SendUserSignupSocialEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUserSignupSocialEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendUserPasswordResetEmail implements IReturnVoid, IEntityLocalizeRequest
{
    public getApiRoute() { return '/api/auth'; }
    public userAuthId: number;
    public secretCode: string;
    public languageCode: string;

    public constructor(init?: Partial<SendUserPasswordResetEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUserPasswordResetEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendUserPasswordResetSuccessEmail implements IReturnVoid, IEntityLocalizeRequest
{
    public getApiRoute() { return '/api/auth'; }
    public userAuthId: number;
    public secretCode: string;
    public languageCode: string;

    public constructor(init?: Partial<SendUserPasswordResetSuccessEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUserPasswordResetSuccessEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveAndGetRequest implements IReturn<Object>
{
    public save: OperationRequest;
    public get: OperationRequest;

    public constructor(init?: Partial<SaveAndGetRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveAndGetRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Object(); }
}

export class Ping implements IReturn<PingResponse>
{

    public constructor(init?: Partial<Ping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Ping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PingResponse(); }
}

export class PingError implements IReturnVoid
{
    public isInner: boolean;

    public constructor(init?: Partial<PingError>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PingError'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BaseModelsRequest implements IReturnVoid
{
    public siteEvent: SiteEvent<string>;
    public globalEvent: GlobalEvent<string>;

    public constructor(init?: Partial<BaseModelsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaseModelsRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class PingSleep implements IReturn<PingSleepResponse>
{
    public milliseconds: number;

    public constructor(init?: Partial<PingSleep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PingSleep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PingSleepResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    public getApiRoute() { return '/api/auth'; }
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

