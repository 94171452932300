import { ref } from 'vue'
import dayjs from 'dayjs'
import { FilterMatchMode } from 'primevue/api'
import { DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primevue/datatable'
import { IReturn, IReturnVoid, JsonServiceClient } from '@servicestack/client'
import { ColumnFilterMatchModeOptions } from 'primevue/column'
import { duration } from 'moment'

export const isEmptyObject = (obj) => {
  return Object.keys(obj ?? {})?.length === 0
}

export const formatDate = (date?: Date | string) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatDateTime = (date?: Date | string) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatServerDateTime = (date?: Date | string) => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss+00:00')
}

export const formatServerTimeSpan = (date?: string) => {
  return duration(date).humanize()
}

export const formatString = (value: string, args: string[]) => {
  return value.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match
  })
}

export const formatTemplateString = (value: string, data: any) => {
  data = data || {}
  const reg = value.match(/{{(.+?)}}/g)
  if (!reg) {
    return value
  }
  reg.forEach(function (key) {
    value = value.replace(key, data[key.replace('{{', '').replace('}}', '')])
  })
  return value
}

export const nameof = <T>(name: keyof T) => name

export const getEnumValues = <T>(T) => {
  return Object.keys(T)
    .map((key) => T[key])
    .filter((value) => typeof value === 'number')
    .map((key) => Number(key))
}

export enum ColumnType {
  Date = 1,
  Number,
  String,
  Boolean,
  Enum,
  Timespan,
}

// TypeScript type-guard
// https://bobbyhadz.com/blog/typescript-check-if-object-implements-interface
export const isDataTableFilterMetaData = (obj: any): obj is DataTableFilterMetaData => {
  return 'value' in obj && 'matchMode' in obj
}
export const isDataTableOperatorFilterMetaData = (obj: any): obj is DataTableOperatorFilterMetaData => {
  return 'operator' in obj && 'constraints' in obj
}

export const getFormatedRouteName = (routeName) => {
  let pos = routeName ? routeName.indexOf('___') : -1
  return pos > -1 ? routeName.slice(0, pos) : routeName
}

export const setCookies = (instance, key, value) => {
  instance.$cookies.set(key, value)
}

export const getCookies = (instance, key) => {
  return instance.$cookies.get(key)
}

export const getRandomColor = (): string => {
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10)
  }
  return color
}

// https://www.primefaces.org/primevue/#/filterservice
// const YOUR_FILTER = ref('YOUR FILTER');
export const matchModeOptions = ref([
  // { label: 'Your Equals', value: YOUR_FILTER.value },
  { label: 'Equals', value: '' },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
])

export const equalMatchMode: ColumnFilterMatchModeOptions = { label: 'Equals', value: '' }

export const containsMatchMode: ColumnFilterMatchModeOptions = { label: 'Contains', value: FilterMatchMode.CONTAINS }

export const isNullMatchMode: ColumnFilterMatchModeOptions = { label: 'Is Null', value: 'IsNull' }

export const lessThanMatchMode: ColumnFilterMatchModeOptions = { label: 'Less Than', value: FilterMatchMode.LESS_THAN }

export const greaterThanMatchMode: ColumnFilterMatchModeOptions = { label: 'Greater Than', value: FilterMatchMode.GREATER_THAN }

export const dateTimeBetweenMatchModes: ColumnFilterMatchModeOptions[] = [lessThanMatchMode, greaterThanMatchMode]

export const matchModeOptionsOnlyEqual = ref([equalMatchMode])

export const matchModeOptionsBool = matchModeOptionsOnlyEqual

export const matchModeOptionsInt = ref([
  { label: 'Equals', value: '' },
  { label: 'Less Than', value: FilterMatchMode.LESS_THAN },
  { label: 'Greater Than', value: FilterMatchMode.GREATER_THAN },
])

export const matchModeOptionsEnum = matchModeOptionsOnlyEqual

export const matchModeOptionsDate = ref([
  { label: 'Date After', value: 'After' },
  { label: 'Date Before', value: 'Before' },
])

export const toBool = (value?: string): boolean | undefined => {
  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }
  return undefined
}

export const getConditionAlias = (matchMode: any): string => {
  var map = {
    lt: 'LessThan',
    gt: 'GreaterThan',
  }
  var matchModeValue = (matchMode && matchMode.matchMode) || matchMode
  var mapValue = map[matchModeValue]
  if (mapValue) {
    return mapValue
  }
  return matchMode
}

export const dataTableFilterToApiReuest = (filters: DataTableFilterMetaDataWithType, lazyParams: LazyParamsType) => {
  const req = {} as any
  req.take = lazyParams.take
  req.skip = lazyParams.skip
  req.include = 'total'
  req.orderBy = lazyParams.orderBy

  for (const filterKey of Object.keys(filters)) {
    const untypedFilter = filters[filterKey]
    if (isDataTableFilterMetaData(untypedFilter)) {
      const filter = untypedFilter as unknown as DataTableFilterMetaDataWithType
      const filterName = `${filterKey}${getConditionAlias(filter.matchMode)}` as any
      if (filter.value) {
        if (+filter.type == ColumnType.Date) {
          req[filterName] = dayjs(filter.value as string).format('YYYY-MM-DD')
        } else {
          req[filterName] = filter.value
        }
      }
    } else if (isDataTableOperatorFilterMetaData(untypedFilter)) {
      const operatorFilter = untypedFilter as DataTableOperatorFilterMetaData
      throw 'not implemented'
    }
  }

  return req
}

/**
 * @extends DataTableFilterMetaData
 */
export interface DataTableFilterMetaDataWithType {
  /**
   * Filter
   * @see DataTableFilterMetaData
   */

  [key: string]: DataTableFilterMetaData | DataTableOperatorFilterMetaData | { isEnabled?: boolean, type?: ColumnType | undefined }
}

export type LazyParamsType = {
  take: number
  skip: number
  orderBy: string
}

export const downloadFile = function (api: JsonServiceClient, request: IReturnVoid, fileName: string | null = null): void {
  const url = api.createUrlFromDto('GET', request)
  const anchor = document.createElement('a')
  anchor.href = url
  if (fileName !== null) {
    anchor.download = fileName
  }
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const uploadFiles = function <T>(api: JsonServiceClient, request: IReturn<T>, files: File[]): Promise<T> {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append('file-' + i, files[i])
  }
  return api.postBody(request, formData)
}

export const openFileDialog = function <T>(): Promise<FileList> {
  return new Promise((resolve) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = (e: Event) => {
      const files = (e.target as any).files
      resolve(files)
    }
    input.click()
  })
}

export const authRedirectUrl = (userSession, route) => {
  if (userSession == null) {
    const isHomePage = route.path == '/' ? true : false
    const routeName = getFormatedRouteName(route.name)
    const pageWithoutLoginig = ['login']

    if (pageWithoutLoginig.includes(routeName)) {
      return null
    }
    return isHomePage ? '/login' : `/login?redirect=${encodeURIComponent(route.fullPath)}`
  }
  return null
}

export const chartOptions = {
  lineData: {
    labels: [] as string[],
    datasets: [
      {
        label: 'Count',
        data: [] as number[],
        fill: false,
        backgroundColor: '#8C5AFF',
        borderColor: '#8C5AFF',
        tension: 0.4,
      },
      {
        label: 'Paid Count',
        backgroundColor: '#00FFC3',
        data: [],
      },
    ],
  },

  barData: {
    labels: [] as string[],
    datasets: [
      {
        label: 'Count',
        data: [] as number[],
        fill: false,
        backgroundColor: '#8C5AFF',
        borderColor: '#8C5AFF',
        tension: 0.4,
      },
      {
        label: 'Paid Count',
        backgroundColor: '#00FFC3',
        data: [],
      },
    ],
  },

  lineOptions: {
    plugins: {
      legend: {
        labels: {
          color: '#231A50',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#231A50',
        },
        grid: {
          color: '#E5FFF7',
        },
      },
      y: {
        ticks: {
          color: '#231A50',
        },
        grid: {
          color: '#E5FFF7',
        },
      },
    },
  },

  barOptions: {
    plugins: {
      legend: {
        labels: {
          color: '#231A50',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#231A50',
        },
        grid: {
          color: '#E5FFF7',
        },
      },
      y: {
        ticks: {
          color: '#231A50',
        },
        grid: {
          color: '#E5FFF7',
        },
      },
    },
  },
}

export const onCopyToClipboard = (value: string, toast?: any) => {
  try {
    const d: any = document
    const el = document.createElement('div')
    const containerId = 'kmb-copy-wrap'
    el.id = containerId
    el.innerHTML = value || ''
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    if (d.selection) {
      const range = d.body.createTextRange()
      range.moveToElementText(document.getElementById(containerId))
      range.select().createTextRange()
      document.execCommand('copy')
    } else if (window.getSelection) {
      const range = d.createRange()
      range.selectNode(document.getElementById(containerId))
      window.getSelection()?.removeAllRanges()
      window.getSelection()?.addRange(range)
      document.execCommand('copy')
      if(toast) {
        toast.add({ severity: 'success', summary: 'Success', detail: 'Copied', life: 3000 })
      }
    }
    document.body.removeChild(el)
  } catch (err) {
    console.log(err)
  }
}
